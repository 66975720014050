<template>
  <v-btn-toggle
    class="d-flex"
    color="primary"
    dense
    mandatory
    rounded
    v-model="selectedButtonIndex"
  >
    <v-tooltip
      bottom
      :disabled="!enableTooltip"
      open-delay="200"
      v-for="(_, idx) in items"
      :key="`two-state-btn-toggle_${idx}`"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="flexible-button"
          v-bind="attrs"
          v-on="on"
          :x-small="small"
        >
          <v-icon
            :small="small"
            v-if="_.icon"
          >{{$icon(_.icon)}}</v-icon>
          <span
            class="ml-2"
            v-if="!hideLabel"
          >{{_.label}}</span>
        </v-btn>
      </template>
      <span>{{$t(_.tooltip)}}</span>
    </v-tooltip>
  </v-btn-toggle>
</template>

<script>
export default {
  components: {
  },
  computed: {
    selectedButtonIndex: {
      get () {
        if (!this.value) {
          return
        }

        const idx = this.items.map(_ => _.value).indexOf(this.value)
        return idx
      },
      set (idx) {
        const v = this.items[idx].value
        this.$emit('update:value', v)
      }
    }
  },
  props: {
    enableTooltip: Boolean,
    small: Boolean,
    hideLabel: {
      type: Boolean,
      default: false
    },
    items: Array,
    value: [String, Boolean, Number]
  }
}
</script>

<style lang="stylus" scoped>
.flexible-button
  flex 1 1 0%
</style>
